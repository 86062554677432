<template>
<div>
    <v-card class="display-block" flat>
        <h3>Vendor Details</h3>
        <v-card-text>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-form ref="form" lazy-validation>
                <v-text-field v-model="vendor.businessName" :rules="businessNameRules" label="Business Name*" required />
                <v-text-field v-model="vendor.address" :rules="businessAddRules" label="Business Address*" required />
                <v-row>
                    <v-autocomplete class="mx-3" v-model="vendor.city" :items="cities" label="Select your city"></v-autocomplete>
                    <v-autocomplete class="mx-3" v-model="vendor.area" :items="cities" label="Select your area"></v-autocomplete>
                </v-row>
                <v-text-field v-model="vendor.slug" label="Slug" :disabled="vendor._id != null" />
                <h5>Access URL :</h5><label>{{url}}</label>
                <v-text-field v-model="vendor.mobileNo" :rules="mobileNoRules" label="Business Contact Number*" lazy />
                <v-text-field v-model="vendor.email" :rules="emailRules" label="Business email" type="Email address" />
                <div v-if="id==0">
                    <h4>Admin Details</h4>
                    <v-row>
                        <v-text-field class="mx-3" v-model="vendor.admin.firstName" :rules="firstNameRules" label="FirstName*" />
                        <v-text-field class="mx-3" v-model="vendor.admin.middleName" label="MiddleName" />
                        <v-text-field class="mx-3" v-model="vendor.admin.lastName" :rules="lastNameRules" label="LastName*" />
                    </v-row>
                    <v-row>
                        <v-text-field class="mx-3" v-model="vendor.admin.mobileNo" :rules="mobileNoRules" label="Mobile Number*" />
                        <v-text-field class="mx-3" v-model="vendor.admin.password" :rules="passwordRules" label="Password*" type="password" />
                    </v-row>
                    <v-text-field v-model="vendor.admin.email" :rules="emailRules" label="email" type="Email address" />
                </div>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-flex text-right>
                    <v-btn class="mr-4" :style="theme" @click="submit()">Save</v-btn>
                    <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    data() {
        return {
            id: 0,
            cities: ["Pune", "Nashik", "Aurangabad"],
            vendor: {
                businessName: "",
                address: "",
                mobileNo: "",
                email: "",
                city: "",
                area: "",
                url: '',
                slug: '',
                admin: {
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    mobileNo: "",
                    password: "",
                    email: ""
                },
            },
        }
    },
    mounted() {
        this.initComponent();
    },
    computed: {
        url() {
            if (!this.vendor.slug) {
                this.vendor.slug = this.vendor.businessName.toLowerCase().replace(/\s+/g, "-").replace(/[^\w-]+/g, '')
            }
            this.vendor.url = this.generateUrl(this.vendor.slug)
            return this.vendor.url
        }
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            if (this.id != 0) {
                this.vendor = await this.getItem(appConstants.VENDORS_API + "/" + this.id)
            }
        },
        async submit() {
            if (this.$refs.form.validate()) {
                try {
                    if (this.id == 0) {
                        await this.postItem(appConstants.VENDORS_API, this.vendor)
                    } else {
                        await this.putItem(appConstants.VENDORS_API + "/" + this.vendor._id, this.vendor)
                    }
                    this.$router.push("/app/vendors")
                } catch (error) {
                    this.handleError(error)
                    this.msg = error.response.data
                }
            }
        },
        generateSlug() {
            if (!this.vendor.slug)
                this.vendor.slug = this.vendor.businessName.toLowerCase().replace(/\s+/g, "-").replace(/[^\w-]+/g, '')
            console.log(this.vendor)
            this.vendor.url = this.generateUrl(this.vendor.slug)
            return this.vendor.url
            //console.log("Slug generated: "+this.vendor.slug)
        }
    },
}
</script>
